import React from "react";
import { PageLayout, ContactForm } from "@bluefin/components";
import { Grid, Header } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import "../style/hiring.css";

export default class HiringPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness } = data;

    return (
      <WebsiteLayout>
        <PageLayout className={"hiring-page"} hero={null}>
          <Grid stackable={true} columns={1}>
            <Grid.Column textAlign={"center"}>
              <Header as={"h1"}>Join our Team</Header>
              <Header textAlign={"center"} as={"h3"}>
                If you're interested in joining our team please submit your
                information below and we'll get in touch with you.
              </Header>
            </Grid.Column>
            <Grid.Column textAlign={"left"}>
              <ContactForm
                constructMessage={({
                  name,
                  email,
                  phone,
                  address,
                  birthdate,
                  workExperience,
                  phoneNumberEmployer,
                  reasonOfLeaving,
                  dateFirstAvailable,
                  daysAvailable,
                  position,
                }) =>
                  `Someone has reached out to you via your website hiring form: Name: ${name}, Email: ${email}, Phone: ${phone}, Address: ${address}, Birthdate: ${birthdate}, Work Experience: ${workExperience}, Phone Number Employer: ${phoneNumberEmployer}, Reason Of Leaving Employer: ${reasonOfLeaving}, Date First Available: ${dateFirstAvailable}, Days Available: ${daysAvailable}, Position ${position}`
                }
                fields={[
                  {
                    label: "name",
                    placeholder: "Full Name",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "email",
                    placeholder: "Email",
                    type: "input",
                    inputType: "email",
                    required: true,
                  },
                  {
                    label: "phone",
                    placeholder: "Phone Number",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "address",
                    placeholder: "Address",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "birthdate",
                    placeholder: "Birthdate",
                    type: "input",
                    inputType: "date",
                    required: true,
                  },
                  {
                    displayLabel: "Previous Work Experience",
                    label: "workExperience",
                    placeholder: "Previous Work Experience",
                    type: "input",
                    required: true,
                  },
                  {
                    displayLabel: "Phone number of current or last employer",
                    label: "phoneNumberEmployer",
                    placeholder: "Phone number of current or last employer",
                    type: "input",
                    required: true,
                  },
                  {
                    displayLabel: "Reason for leaving previous employer",
                    label: "reasonOfLeaving",
                    placeholder: "Reason for leaving previous employer",
                    type: "textarea",
                    required: true,
                  },
                  {
                    label: "dateFirstAvailable",
                    displayLabel: "Date first available",
                    placeholder: "Date first available",
                    type: "input",
                    inputType: "date",
                    required: true,
                  },
                  {
                    label: "daysAvailable",
                    placeholder: "Days Available",
                    type: "multiSelect",
                    required: true,
                    options: [
                      { label: "Monday", value: "Monday" },
                      { label: "Tuesday", value: "Tuesday" },
                      { label: "Wednesday", value: "Wednesday" },
                      { label: "Thursday", value: "Thursday" },
                      { label: "Friday", value: "Friday" },
                      { label: "Saturday", value: "Saturday" },
                      { label: "Sunday", value: "Sunday" },
                    ],
                  },
                  {
                    label: "position",
                    displayLabel: "Position",
                    type: "select",
                    required: true,
                    options: [
                      { label: "Driver", value: "Driver" },
                      { label: "Cook", value: "Cook" },
                      { label: "Pizza Man", value: "Pizza Man" },
                      { label: "Cashier/Counter", value: "Cashier/Counter" },
                    ],
                  },
                ]}
                emailSubject={"Hiring Request"}
                hideOptIn={true}
                buttonText={"Send"}
                centeredHeaders={true}
                className={""}
                fluidButton={true}
                header={""}
                inline={false}
                showLabels={true}
                subheader={""}
                termsCopy={""}
                withContainer={true}
                locationId={fishermanBusiness.primaryLocation._id}
                businessId={fishermanBusiness._id}
                businessType={fishermanBusiness.type}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      primaryLocation {
        _id
      }
      _id
      type
    }
  }
`;
